<template>
  <div class="clients" :class="{'locale-usa': locale === 'USA'}">
    <div class="container">
      <div class="clients__title">
        {{ $t('clients.title') }}
      </div>

      <div class="clients__filters" v-if="this.$i18n._vm.locale==='en'">
        <Search :placeholder="$t('search_props.name')" @findValue="changeSearchText" />
        <Selector 
          :title="$t('selector_props.client_type')" 
          :options="clientTypes.en" 
          @handleChange="getNewData" 
          :ruValues="ruValues" 
          :enValues="enValues" 
        />
        <div>
          <el-popover
            placement="bottom-end"
            trigger="hover"
          >
            <div class="clients__filters-download-table">
              <div class="clients__filters-download-table-row" :class="{'locale-usa': locale === 'USA'}" @click="getReport({payload:'bar'}, $t('button_props.report_business'))">
                {{ $t('button_props.report_business') }}
              </div>
              <div class="clients__filters-download-table-row" :class="{'locale-usa': locale === 'USA'}" @click="getReport({payload:'aar'}, $t('button_props.report_ad_google'))">
                {{ $t('button_props.report_ad_google') }}
              </div>
              <div class="clients__filters-download-table-row" :class="{'locale-usa': locale === 'USA'}" @click="getReport({payload:'ad'}, $t('button_props.get_google_ad'))">
                {{ $t('button_props.get_google_ad') }}
              </div>
              <!-- <div class="clients__filters-download-table-row" @click="getReport('google', $t('button_props.get_google_ad'))">
                {{ $t('button_props.get_google_ad') }}
              </div> -->
              <!-- <div class="clients__filters-download-table-row" :class="{'locale-usa': locale === 'USA'}" @click="getReportMain($t('button_props.export_data'))">
                {{ $t('button_props.export_data') }}
              </div> -->
              <div class="clients__filters-download-table-row" :class="{'locale-usa': locale === 'USA'}"  @click="getReportBa">
                {{ $t('button_props.business_account_statistics') }}
              </div>
            </div>
            <button slot="reference" class="clients__filters-download"/>
          </el-popover>
        </div> 
      </div>
      <div class="clients__filters" v-if="this.$i18n._vm.locale==='ru'">
        <Search :placeholder="$t('search_props.name')" @findValue="changeSearchText" />
        <Selector 
          :title="$t('selector_props.client_type')" 
          :options="clientTypes.ru" 
          @handleChange="getNewData"
          :ruValues="ruValues" 
          :enValues="enValues"
        />
        <div>
          <el-popover
            placement="bottom-end"
            trigger="hover"
          >
            <div class="clients__filters-download-table">
              <div class="clients__filters-download-table-row" @click="getReport({payload:'bar'}, $t('button_props.report_business'))">
                {{ $t('button_props.report_business') }}
              </div>
              <div class="clients__filters-download-table-row" @click="getReport({payload:'aar'}, $t('button_props.report_ad_google'))">
                {{ $t('button_props.report_ad_google') }}
              </div>
              <div class="clients__filters-download-table-row" @click="getReport({payload:'ad'}, $t('button_props.get_google_ad'))">
                {{ $t('button_props.get_google_ad') }}
              </div>
              <!-- <div class="clients__filters-download-table-row" @click="getReport('google', $t('button_props.get_google_ad'))">
                {{ $t('button_props.get_google_ad') }}
              </div> -->
              <!-- <div class="clients__filters-download-table-row" :class="{'locale-usa': locale === 'USA'}" @click="getReportMain($t('button_props.export_data'))">
                {{ $t('button_props.export_data') }}
              </div> -->
              <div class="clients__filters-download-table-row" @click="getReport({payload:'saa'}, $t('button_props.expense_report'))">
                {{ $t('button_props.expense_report') }}
              </div>
              <div class="clients__filters-download-table-row" @click="getReportBa">
                {{ $t('button_props.business_account_statistics') }}
              </div>
            </div>
            <button slot="reference" class="clients__filters-download"/>
          </el-popover>
        </div> 
      </div>

      <div v-if="usersList && usersList.length" class="clients__table" v-loading="loading">
        <el-table :data="usersList" :row-class-name="tableRowClassName" style="width: 100%">
          <el-table-column width="100" class-name="clients__table-id" label="ID">
            <template slot-scope="scope">
              <span class="clients__table-id__short" :class="{'locale-usa': locale === 'USA'}" @click="copyId(scope.row.id)">
                <span>
                  {{ scope.row.id }}
                </span>
              </span>

              <br>
              <span class="clients__table-date">
                {{ fixDateFormat(scope.row.created_at) }}
              </span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('clients.table_lable_type')" width="120">
            <template slot-scope="scope">
              <span>
                {{ scope.row.is_legal ? $t('clients.legal.0') : $t('clients.legal.1') }}
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="legal_name" :label="$t('clients.table_lable_name')" class-name="wrap-words"
            :width="width <= 992 ? '150' : ''" />

          <!-- <el-table-column :label="$t('clients.table_lable_target_type')">
            <template slot-scope="scope">
              <span>
                {{
                  scope.row.agency_account_type && scope.row.agency_account_type.mytarget && scope.row.agency_account_type.mytarget === 'white' ? 'AM' : $t('clients.colors.1')
                }}
              </span>
            </template>
          </el-table-column> -->


          
          <el-table-column :label="$t('clients.table_lable_contact_person')" :width="width <= 992 ? '150' : ''">
            <template slot-scope="scope">
              <span v-if="scope.row.is_legal">
                {{ scope.row.legal_contact_name }}
              </span>
              <span v-else>
                {{ scope.row.first_name ? scope.row.first_name : '' }}
                {{ scope.row.last_name ? scope.row.last_name : '' }}
              </span>
            </template>
          </el-table-column>

          <el-table-column :width="width <= 992 ? '200' : ''" :label="$t('clients.table_lable_contacts')">
            <template slot-scope="scope">
              <span>
                <a class="clients__table-email" :href="`mailto:${scope.row.email}`">{{
                  scope.row.email ? scope.row.email
                    : ''
                }}</a>
                <br>
                {{ scope.row.phone ? scope.row.phone : '' }}
              </span>
            </template>
          </el-table-column>

          <el-table-column :width="width <= 992 ? '200' : ''">
            <template slot-scope="scope">
              <span v-if="scope.row.is_active === false" class="clients__table-deactivated">{{
                $t('clients.deactivated')
              }}</span>
              <button v-else class="clients__table-button" @click="authAsUser(scope.row.id)"
                v-html="$t('clients.authorize_with_user')">
              </button>
            </template>
          </el-table-column>

          <el-table-column width="30" class-name="clients__table-last">
            <template>
              <span>
                <svg width="3" height="17" viewBox="0 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M3 1.5C3 2.32843 2.32843 3 1.5 3C0.671573 3 0 2.32843 0 1.5C0 0.671573 0.671573 0 1.5 0C2.32843 0 3 0.671573 3 1.5ZM3 8.5C3 9.32843 2.32843 10 1.5 10C0.671573 10 0 9.32843 0 8.5C0 7.67157 0.671573 7 1.5 7C2.32843 7 3 7.67157 3 8.5ZM1.5 17C2.32843 17 3 16.3284 3 15.5C3 14.6716 2.32843 14 1.5 14C0.671573 14 0 14.6716 0 15.5C0 16.3284 0.671573 17 1.5 17Z"
                    fill="#9BA2AB" />
                </svg>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div v-if="usersList && !usersList.length">
        {{ $t('nothing_found') }}
      </div>

      <div class="clients__pagination">
        <el-pagination v-if="pagination && pagination.total>=10" layout="prev, pager, next" :total="pagination.total"
          :page-size="10" :current-page="pagination.currentPage" @current-change="getNewPage" />
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/sort/Search";
import Selector from "@/components/sort/Selector";
import fixDateFormat from '@/mixin/fixDate'

export default {
  name: 'Clients',
  components: {
    Search,
    Selector,
  },
  mixins: [fixDateFormat],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      ruValues: ['Все','Физлицо','Юрлицо'],
      enValues: ['All', 'Individual', 'Entity'],
      clientTypes1: [
        {
          id: 0,
          name: this.$t('clients.clientTypes.0')
        },
        {
          id: 1,
          name: this.$t('clients.clientTypes.1')
        },
        {
          id: 2,
          name: this.$t('clients.clientTypes.2')
        }
      ],
      clientTypes: {
        'ru': [
          
          {
            id: 0,
            name: "Все"
          },
          {
            id: 1,
            name: "Физлицо"
          },
          {
            id: 2,
            name:  "Юрлицо"
          }
        ],
        'en': [
          {
            id: 0,
            name: "All",
          },
          {
            id: 1,
            name: "Individual",
          },
          {
            id: 2,
            name:  "Entity"
          }
        ]
      },
      loading: false,
      params: {
        per_page: 10,
      },
      width: null,
    }
  },
  computed: {
    usersList() {
      return this.$store.state.Users.usersList
    },
    pagination() {
      return this.$store.state.Users.pagination
    },
  },
  watch: {
    $route() {
      for (let key in this.$route.query) {
        this.params[key] = this.$route.query[key]
      }
      this.getData()
    }
  },
  created() {
    this.$store.commit('Auth/setData', { label: 'activeAccount', data: null })
    for (let key in this.$route.query) {
      this.params[key] = this.$route.query[key]
    }
    this.getData()
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();
  },
  methods: {
    async getData() {
      this.loading = true
      const token = localStorage.getItem('token')
      await this.$store.dispatch('Users/getUsersList', { params: this.params, token })
      this.loading = false
    },
    authAsUser(id) {
      const data = {
        id,
        token: localStorage.getItem('token')
      }
      this.$store.dispatch('Auth/authAsUser', data)
        .then(() => {
          this.$router.push({ path: '/statistics' })
        })
    },
    changeSearchText(val) {
      if (!val) {
        const query = { ...this.$route.query, page: 1, search_text: null }
        this.$router.push({ query })
      } else {
        const query = { ...this.$route.query, page: 1, search_text: val }
        this.$router.push({ query })
      }
    },
    getNewPage(page) {
      const query = { ...this.$route.query, page }
      this.$router.push({ query })
    },
    getNewData(val) {
      if (val === 0) {
        const query = { ...this.$route.query, page: 1, is_legal: null }
        this.$router.push({ query })
      } else {
        const status = val !== 1
        const query = { ...this.$route.query, page: 1, is_legal: status }
        this.$router.push({ query })
      }
    },
    copyId(id) {
      navigator.clipboard.writeText(id)
        .then(() => {
          this.$notify({
            message: this.$t('clients.message'),
            type: 'success'
          });
        })
        .catch(err => {
          console.log('Something went wrong', err);
        });
    },
    tableRowClassName({ row }) {
      if (row.is_active === false) {
        return 'el-table__row--deactivated';
      }
      return '';
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
    getReport(platform, dialogTitle) {
      if (platform) {
        this.$store.commit('Dialog/setData', { label: 'baActivePlatform', data: platform })
      }
      this.$store.commit('Dialog/setData', { label: 'dialogTitle', data: dialogTitle })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'GetBusinessXLS' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    getReportMain(dialogTitle) {
      this.$store.commit('Dialog/setData', { label: 'dialogTitle', data: dialogTitle })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'GetBusinessXLS' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },
    getReportBa() {
      if(this.locale === 'RU') {
        if(process.env.VUE_APP_API_URL === 'https://api.ads-profit.com/api/v1' ) {
          const url = 'https://storageprofitads.storage.yandexcloud.net/data_ba.xlsx';
          this.createInvisibleLink(url)
        } else {
          const url = 'https://storageprofitads.storage.yandexcloud.net/data_ba_dev_ru.xlsx';
          this.createInvisibleLink(url)
        }
      } else if(this.locale === 'USA') {
        if(process.env.VUE_APP_API_URL === 'https://api.digital-eagle.us/api/v1') {
          const url = 'https://digital-eagle-storage.s3.amazonaws.com/data_ba.xlsx';
          this.createInvisibleLink(url)
        } else {
          const url = 'https://digital-eagle-storage.s3.amazonaws.com/data_ba_dev_usa.xlsx';
          this.createInvisibleLink(url)
        }
      }
    },
    createInvisibleLink(url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = 'build.xlsx';
      document.body.appendChild(link);
      link.click();
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.clients {
  padding: 5rem 0;
  &.locale-usa {
    .clients__title {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 700;
      font-size: 3.2rem;
      line-height: 4rem;
    }
    .el-table__header, .el-table__body {
      font-family: 'Gilroy'; 
    }
    .el-table__body {
      .el-table__row {
        .el-table__cell:nth-child(-n+2) {
          font-weight: 700;
          .clients__table-date {
            font-weight: initial;
          }
        }
      }
    }
    
  }

  &__title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.6rem;
    margin-bottom: 3rem;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;

    .search {
      width: 70%;
    }

    .selector {
      margin-left: 1rem;
      width: 28%;

      &__content {
        width: 100%;
      }
    }
    &-download {
      width: 5rem;
      height: 5rem;
      flex-basis: 5rem;
      border: none;
      background: none;
      margin-left: 1rem;
      background-image: url('../assets/icons/icon-xlsx-document.svg');
      background-repeat: no-repeat;
      background-position: center center;
    }

    &-download-table {
      display: flex;
      flex-direction: column;
    }

    &-download-table-row {
      cursor: pointer;
      padding: 1rem;
      transition: .3s;
      border-radius: 4px;

      &:hover {
        color: #fff;
        background: #2D52D2;
      }
    }

    &-download-table-row.locale-usa {
      &:hover {
        background: #FF7F50;
      }
    }

  }

  &__table {

    .el-table__row {

      &--deactivated {

        td {
          background-color: rgba($redLight, .1);
        }

        &:hover {

          td {
            background-color: rgba($redLight, .2) !important;
          }
        }
      }
    }

    &-button {
      width: 100%;
      background: var(--primary);
      border: 1px solid var(--primary);
      padding: 1rem 2rem;
      border-radius: 4px;
      color: #fff;
    }

    &-date {
      color: #9BA2AB;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }

    &-id {

      .cell {
        position: relative;
        overflow: visible;
      }

      &__short {
        display: inline-block;
        position: relative;

        span {
          cursor: pointer;
          width: 8rem;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: color .4s;
        }

        &::before {
          cursor: pointer;
          z-index: 20;
          width: 18px;
          height: 18px;
          content: '';
          position: absolute;
          left: 90%;
          bottom: 90%;
          background-image: url(../assets/icons/icon-copy-id.svg);
          background-repeat: no-repeat;
          opacity: 0;
          transition: opacity .4s;
        }

        &.locale-usa {
          &::before {
            background-image: url(../assets/icons/icon-copy-id-usa.svg);
          }
        }

        &:hover {

          span {
            color: var(--primaryHover);
          }

          &::before {
            opacity: 1;
          }
        }
      }
    }

    &-email {
      display: inline-block;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.9rem;
      color: var(--primary);
      transition: color .4s;
      margin: 0 0 .5rem;

      &:hover {
        color:var(--primaryHover);
      }
    }

    &-deactivated {
      width: 100%;
      display: inline-block;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.9rem;
      color: $redLight;
      text-align: center;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .el-pagination .btn-next,
    .el-pagination .btn-prev,
    .el-pager li {
      background: transparent;
    }
  }

  @include below(993px) {
    padding-top: 32px;

    &__title {
      font-size: 24px;
      line-height: 1.4;
      margin-bottom: 24px;
    }

    &__filters {
      margin-bottom: 24px;
    }
  }

  @include below(576px) {
    padding-top: 24px;

    &__title {
      font-size: 20px;
      margin-bottom: 16px;
    }

    &__filters {
      flex-direction: column;
      margin-bottom: 16px;

      .search {
        width: 100%;
        margin-bottom: 8px;
      }

      .selector {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
</style>
