<template>
  <header class="app-header" :class="{'locale-usa': locale === 'USA'}">
    <div class="app-header--left">
      <div class="app-header__burger" :class="{ 'app-header__burger--close': showMobileMenu }" @click="burgerClick">
        <svg class="open" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="14" width="24" height="4" rx="1" fill="#333333" />
          <rect x="4" y="3" width="24" height="4" rx="1" fill="#333333" />
          <rect x="4" y="25" width="24" height="4" rx="1" fill="#333333" />
        </svg>

        <svg class="close" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="8.92896" y="6.10051" width="24" height="4" rx="1" transform="rotate(45 8.92896 6.10051)"
            fill="#333333" />
          <rect x="6.10059" y="23.0711" width="24" height="4" rx="1" transform="rotate(-45 6.10059 23.0711)"
            fill="#333333" />
        </svg>
      </div>

      <div class="app-header__logo">
        <router-link :to="{ name: 'Home' }">
          <img :src="getPic(locale)" alt="ProfitAds">
        </router-link>
      </div>

      <div class="app-header__nav">
          <div v-if="role !== 'partner'" class="app-header__nav-item" :class="{'locale-usa': locale === 'USA'}">
            <router-link :to="{ name: 'Home' }" class="app-header__nav-item">
              {{ $t('header.statistics') }}
            </router-link>
        </div>
        <div class="app-header__nav-item" :class="{'locale-usa': locale === 'USA'}">
          <router-link :to="getTargetRoute()" class="app-header__nav-item">
            {{$t('header.accounts')}}
          </router-link>
        </div>
        <div v-if="role === 'partner' && isAddBallance" class="app-header__nav-item" :class="{'locale-usa': locale==='USA'}">
            <router-link :to="{ name: 'History' }" class="app-header__nav-item">
                {{ $t('header.history') }}
            </router-link>
        </div>
        <div v-if="locale === 'RU' && isShowCreatives" class="app-header__nav-item" :class="{'locale-usa': locale === 'USA'}">
          <router-link :to="'/creatives?view=clients'" class="app-header__nav-item">
            ОРД
          </router-link>
        </div>
        <div v-if="role === 'manager' || role === 'user'" class="app-header__nav-item" :class="{'locale-usa': locale === 'USA'}">
          <router-link :to="{ name: 'Representatives' }" class="app-header__nav-item">
            {{$t('header.representatives')}}
          </router-link>
        </div>
        <div v-if="role !=='partner'" class="app-header__nav-item app-header__nav-wrapper" :class="{ 'locale-usa': locale === 'USA' }">
          {{ $t('header.finance') }}
          <img src="@/assets/icons/icon-arrow.svg" alt="arrow image">
            <div class="app-header__sub-header submenu">
              <div class="submenu_wrapper">
                <div class="submenu__item" :class="{'locale-usa': locale==='USA'}">
                  <router-link :to="{ name: 'Documents' }">
                    {{ $t('header.documents') }}
                  </router-link>
                </div>
                <div class="submenu__item" :class="{'locale-usa': locale==='USA'}">
                  <router-link :to="{ name: 'History' }">
                    {{ $t('header.history') }}
                  </router-link>
                </div>
                <div class="submenu__item" :class="{'locale-usa': locale==='USA'}">
                  <router-link :to="{ name: 'Cashback' }">
                  {{ $t('header.cashback_and_commission') }}
                  </router-link>
                </div>
              </div>
            </div>
          
        </div>

        <div v-if="user.is_holding && role !=='partner'" class="app-header__nav-item" :class="{'locale-usa': locale === 'USA'}">
          <router-link :to="{ name: 'BusinessAccounts' }" class="app-header__nav-item">
           {{ $t('header.business_accounts') }}
          </router-link>
        </div>
        <!-- <div v-if="locale === 'USA'" class="app-header__nav-item" :class="{'locale-usa': locale==='USA'}">
              <router-link :to="{ name: 'VkAdds' }">
                {{ $t('header.vkads') }}
              </router-link>
        </div> -->
        <!-- <div v-if="locale === 'RU'" class="app-header__nav-item app-header__nav-wrapper" :class="{'locale-usa': locale === 'USA'}">
          {{ $t('header.additional_accounts') }}
          <img src="@/assets/icons/icon-arrow.svg" alt="arrow image">
          <div class="app-header__sub-header submenu">
            <div class="submenu_wrapper">
              <div class="submenu__item" :class="{'locale-usa': locale==='USA'}">
                <router-link :to="{ name: 'VkAccounts' }">
                  {{ $t('header.vkontakte') }}
                </router-link>
              </div>
              <div class="submenu__item" :class="{'locale-usa': locale==='USA'}">
                <router-link :to="{ name: 'VkAdds' }">
                  {{ $t('header.vkads') }}
                </router-link>
              </div>
            </div>
          </div>
        </div> -->
        <div v-if="role !=='partner'" class="app-header__nav-item">
          <router-link :to="{ name: 'Support' }" class="app-header__nav-item support" :class="{'locale-usa': locale === 'USA'}">
            <span>{{ $t('header.support_service') }}</span>
            <NotificationElement v-if="role==='user'" :notification="unreadNotifications"/>
          </router-link>
          
        </div>
        <div v-if="role !=='partner'" class="app-header__nav-item" :class="{'locale-usa': locale === 'USA'}">
          <router-link :to="{ name: 'HelpDesk' }" class="app-header__nav-item">
            FAQ
          </router-link>
        </div>
        <div v-if="moreLinks.length && role !=='partner'">
          <el-popover placement="bottom" trigger="hover">
            <div class="app-header__nav-more">
              <div v-for="(item, idx) in moreLinks" :key="idx" class="app-header__nav-more-link" :class="{'locale-usa': locale === 'USA'}">
                <router-link :to="item.link">
                  {{ item.name }}
                </router-link>
              </div>
            </div>
            <div slot="reference" class="app-header__nav-more-link" :class="{'locale-usa': locale === 'USA'}">
              {{$t('header.more')}}
              <img src="@/assets/icons/icon-arrow.svg" alt="">
            </div>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="app-header__prof-lang-container">
      <div class="app-header--right" @click="showMobileMenu = false">
        <ProfileCard />
      </div>
    </div>
   

    <el-dialog class="mobile-menu" :class="{ 'mobile-menu--show': showMobileMenu }" :visible.sync="showMobileMenu"
      :modal="false" :fullscreen="true">
      <div class="mobile-menu__body">
        <ul class="mobile-menu__list" :class="{'locale-usa': locale === 'USA'}">
          <li class="mobile-menu__list-item">
            <router-link :to="{ name: 'Home' }" @click.native="burgerClick" >
              {{ $t('header.statistics') }}
            </router-link>
          </li>

          <li class="mobile-menu__list-item">
            <router-link :to="{ name: 'Ad' }" @click.native="burgerClick">
              {{$t('header.accounts')}}
            </router-link>
          </li>

          <li class="mobile-menu__list-item">
            <router-link :to="{ name: 'Cashback' }" @click.native="burgerClick">
              {{ $t('header.cashback_and_commission') }}
            </router-link>
          </li>

          <li class="mobile-menu__list-item">
            <router-link :to="{ name: 'History' }" @click.native="burgerClick">
              {{ $t('header.history') }}
            </router-link>
          </li>

          <li class="mobile-menu__list-item">
            <router-link :to="{ name: 'Documents' }" @click.native="burgerClick">
              {{ $t('header.documents') }}
            </router-link>
          </li>

          <li v-if="user.is_holding" class="mobile-menu__list-item">
            <router-link :to="{ name: 'BusinessAccounts' }" @click.native="burgerClick">
              {{ $t('header.business_accounts') }}
            </router-link>
          </li>
          <li class="mobile-menu__list-item">
            <router-link :to="{ name: 'VkAccounts' }" @click.native="burgerClick">
              {{$t('header.vkontakte')}}
            </router-link>
          </li>
          <!-- <li class="mobile-menu__list-item">
            <router-link :to="{ name: 'VkAdds' }" @click.native="burgerClick">
             {{$t('header.vkads')}}
            </router-link>
          </li> -->
          <li class="mobile-menu__list-item">
            <router-link :to="{ name: 'Support' }" @click.native="burgerClick" class="support">
              <span>{{ $t('header.support_service') }}</span>
              <NotificationElement v-if="role==='user'" :notification="unreadNotifications"/>
            </router-link>
          </li>
          <li class="mobile-menu__list-item">
            <router-link :to="{ name: 'HelpDesk' }" @click.native="burgerClick">
              FAQ
            </router-link>
          </li>
          <li class="mobile-menu__list-item">
            <router-link 
              :to="{ name: 'Creatives', query: { 'view': 'clients' }}" 
              @click.native="burgerClick" 
            >
              ОРД
            </router-link>
          </li>
        </ul>
      </div>
    </el-dialog>
  </header>
</template>

<script>
import checkCashback from '@/mixin/checkCashback'
import ProfileCard from '@/components/profile/ProfileCard';
import NotificationElement from '@/components/elements/NotificationElement';


export default {
  name: "Header",
  components: {
    ProfileCard,
    NotificationElement

  },
  props: {
    isAddBallance: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showMobileMenu: false,
      moreLinks: [],
      colors: {},
      locale: process.env.VUE_APP_LOCALE
    };
  },
  mixins: [checkCashback],
  computed: {
    user() {
      return this.$store.state.Auth.user
    },
    unreadNotifications() {
        return this.$store.getters['Auth/unreadNotifications']
    },
    role() {
      return this.$store.state.Auth.role
    },
    showForPartner() {
      if (this.role === 'partner') {
        if(this.activeAccount && this.activeAccount.services && this.activeAccount.services.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    isShowCreatives() {
      if (this.role === 'partner' && this.activeAccount?.permissions?.length) {
        return this.activeAccount.permissions.includes("advert.telegram.view");
      }
      return true;
    }
  },
  created() {
    this.colors = this.$colors();
    this.$store.dispatch('Auth/getUnreadNotifications')
  },
  mounted() {
    window.addEventListener('resize', this.cropMenu);
    this.cropMenu()
    this.$store.dispatch('Auth/getUnreadNotifications')
  },
  unmounted() {
    window.removeEventListener('resize', this.cropMenu);
  },
  methods: {
    getPic(locale) {
      if(locale==='RU') {
        return  require("@/assets/images/logo.svg")
      } else {
        return require("@/assets/images/logo_new.svg")
      }
    },
    burgerClick() {
      this.showMobileMenu = !this.showMobileMenu
      if (this.showMobileMenu === true) {
        this.open();
      } else {
        this.close();
      }
    },
    open() {
      document.body.classList.add('el-popup-parent--hidden')
    },
    close() {
      document.body.classList.remove('el-popup-parent--hidden')
    },
    cropMenu() {
      const menuItems = document.querySelectorAll('div.app-header__nav-item')
      const submenuItems = document.querySelectorAll('.submenu__item')
      menuItems.forEach(element => {
        element.classList.remove('is-hidden')
      })
      submenuItems.forEach(element => {
        element.classList.remove('is-hidden')
      })
      this.moreLinks = []
      let widthSum = 150
      const header = document.querySelector('.app-header')
      const logo = document.querySelector('.app-header__logo')
      const profileСard = document.querySelector('.app-header--right')
      const menuWidth = header.offsetWidth - ((logo.offsetWidth + 75) + (profileСard.offsetWidth < 300 ? 300 : profileСard.offsetWidth))
      menuItems.forEach(element => {
        widthSum += (element.offsetWidth + 45)
        if (element && widthSum >= menuWidth) {
          if (element.classList.contains('app-header__nav-wrapper') && submenuItems.length) {
            element.querySelectorAll('.submenu__item').forEach(item => {
              setTimeout(() => {
                this.moreLinks.push({
                  name: item.querySelector('a').innerText,
                  link: item.querySelector('a').getAttribute("href"),
                })
                item.classList.add('is-hidden')
              }, 0)
            })
          }
          this.moreLinks.push({
            name: element.querySelector('a').innerText,
            link: element.querySelector('a').getAttribute("href"),
          })
          element.classList.add('is-hidden')
        }
      });
    },
    preventNav() {
      return false
    },
    getTargetRoute() {
      if (this.role === 'partner') {
        return { name: 'AdRepresentative' };
      } else {
        return { name: 'Ad' };
      }
    },
  },
    changeLang() {
      this.$store.commit('changeLang')
      this.$i18n.locale = this.$store.getters.language;
    }
  }

</script>

<style scoped lang="scss">
@import '@/assets/styles/helpers/mixin';
@import '@/assets/styles/helpers/variables';

.notification {
    display: inline-block;
    padding: 2px 6px;
    color: #fff;
    background: var(--primary);
    border-radius: 50%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    min-width: 18px;
    min-height: 18px;
}
.app-header {
  &.locale-usa {
    box-shadow: 0px 2px 19px rgba(0, 0, 0, 0.08);
  }
  display: flex;
  justify-content: space-between;
  padding: 2rem 4rem;
  background: $basicWhite;
  box-shadow: 0 1px 0 #e3e6ec;


  &--left {
    display: flex;
    align-items: center;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7rem;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--primary);
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2rem;

      img {
        width: 120px;
        display: block;
        height: auto;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    &-more {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &-more-link {
      &.locale-usa {
        font-family: 'Avenir Next Cyr';
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.4rem;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
      font-weight: 500;
      font-size: 1.6rem;
      color: $text;
      position: relative;
      margin-right: 4.5rem;
      height: 100%;
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }

    &-item {
      .support {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .notification {
          margin-left: 6px;
        }
      }
      &.locale-usa {
        font-family: 'Avenir Next Cyr';
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.4rem;
      }
      font-weight: 500;
      font-size: 1.8rem;
      color: $text;
      position: relative;
      margin-right: 4.5rem;
      height: 100%;
      display: flex;
      align-items: center;
      flex-shrink: 0;

      &.is-hidden {
        display: none;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 0;
        border-radius: 5px;
        background: var(--primary);
        position: absolute;
        left: 0;
        top: calc(100% + 1.9rem);
        transition: 0.3s ease-out;
      }

      &.router-link-exact-active, &.router-link-active {
        &::before {
          height: 3px;
        }
      }

      &:hover {
        &::before {
          height: 3px;
        }
      }

      &.disabled {
        cursor: default;
        opacity: 0.7;

        &::before {
          display: none;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__burger {
    width: 24px;
    height: 24px;
    position: relative;
    cursor: pointer;
    display: none;
    margin-right: 16px;

    svg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      transition: opacity .4s, transform .4s;

      &.close {
        transform: translate3d(-50%, -50%, 0) scale(0);
        opacity: 0;
      }
    }

    &--close {

      svg.open {
        transform: translate3d(-50%, -50%, 0) scale(0);
        opacity: 0;
      }

      svg.close {
        transform: translate3d(-50%, -50%, 0) scale(1);
        opacity: 1;
      }
    }
  @include below(577px) {
    // top: 68px;
  }
}

&__prof-lang-container {
  display: flex;
  align-items: center;
}

  @include below(993px) {
    padding: 16px;

    &__logo {
      margin-right: 0;
    }

    &__nav {
      display: none;
    }

    &__burger {
      display: flex;
    }
  }
}

.app-header__nav-wrapper {
  position: relative;
  &:has(.router-link-active)::before, &:has(.router-link-exact-active)::before {
    min-height: 3px;
  }
}

.submenu_wrapper {
  border-radius: 4px;
  border: 1px solid #EBEEF5;
  padding: 12px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  background: #FFF;
  
}
.submenu {
  padding-top: 1rem;
  display: flex;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  flex-direction: column;
  gap: 1rem;
  top: 100%;
  left: -1.5rem;
  position: absolute;
  min-width: min-content;
  width: 100%;
  z-index: 2000;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  white-space: nowrap;
}

// .submenu::before {
//   content: ''; 
//     position: absolute;
//     border: 5px solid transparent;
//     border-bottom: 10px solid #fff;
//     display: block;
//     top: -5%;
//     left: 10%;
//     transform: translateX(-50%);
// }

.app-header__nav-wrapper:hover .app-header__sub-header {
  visibility: visible;
  opacity: 1;
}

.submenu__item {
  &.locale-usa {
    font-family: 'Avenir Next Cyr';
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
  }
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  font-weight: 500;
  font-size: 1.6rem;
  color: #333;
  margin-right: 4.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  .app-header__nav-item::before {
    top: 100%;
  }
}

.mobile-menu {
  top: 71px;
  background: rgba(255, 255, 255, 0.96);

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0 0 32px;

    &-item {
      margin: 0 0 24px;
      .support {
        &.locale-usa {
        font-family: 'Avenir Next Cyr';
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        .notification {
          margin-left: 6px;
        }
      }
      a,
      div {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.4;
        color: $basicBlack;
        text-align: center;
        transition: color .4s;

        &:hover {
          color: var(--primary);
        }
      }
      .router-link-exact-active, .router-link-active {
        color: var(--primary);
      }

      div {
        &.disabled {
          pointer-events: none;
          cursor: default;
          opacity: 0.7;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
    &.locale-usa {
      a:hover {
        color: var(--primary);
      }
    }
  }

  @include below(577px) {
    top: 68px;
  }
}

@include below(375px) {
  .app-header__logo a img {
    width: 90px;
  }
  .app-header__burger {
    margin-right: 5px;
  }
}
</style>
